<template>
  <div>
    <v-card>
      <v-card-text>
        <h3 class="headline">ແກ້ໄຂ Promotion</h3>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols>
                <v-text-field
                    label="Promotion Code"
                    v-model="promotion.pro_code"
                    outlined
                    dense
                ></v-text-field>
                <p class="error--text">{{server_errors.pro_code}}</p>
              </v-col>
              <v-col cols>
                <v-text-field
                    label="Promotion name"
                    v-model="promotion.name"
                    outlined
                    dense
                ></v-text-field>
                <p class="error--text">{{server_errors.name}}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols>
                <v-checkbox v-model="promotion.specific"
                            label="ໃຊ້ໄດ້ສະເພາະລູກຄ້າທີ່ເຈາະຈົງ"
                            row>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols>
                <v-checkbox v-model="promotion.limit_min_price"
                            label="ລາຄາຊື້ຂັ້ນຕ່ຳ"
                            row>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="promotion.limit_min_price">
              <v-col>
                <v-text-field
                    label="ຂັ້ນຕຳ"
                    v-model="promotion.buy_min_price"
                    type="number"
                    outlined
                    dense
                ></v-text-field>
                <p class="error--text">{{server_errors.buy_min_price}}</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols>
                <v-checkbox v-model="promotion.limit_use"
                            label="ຈຳກັດການໃຊ້ຕໍ່ User"
                            row>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="promotion.limit_use">
              <v-col cols>
                <v-text-field
                    label="ຈຳນວນຄັ້ງ"
                    v-model="promotion.amount_use"
                    type="number"
                    outlined
                    dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-select
                    required
                    v-model="promotion.pro_type"
                    :items="promotionType"
                    item-text="name"
                    item-value="id"
                    label="ປະເພດສ່ວນຫຼຸດ *"
                    outlined
                    dense
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="ປະລິມານສ່ວນຫຼູດ"
                    v-model="promotion.volume"
                    type="number"
                    outlined
                    dense
                ></v-text-field>
              </v-col>
              <p class="error--text">{{server_errors.volume}}</p>
            </v-row>
            <v-row>
              <v-col cols>
                <v-menu
                    v-model="expired_menu"
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="promotion.expired_date"
                        label="ວັນໝົດອາຍຸ"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="promotion.expired_date"
                  ></v-date-picker>
                </v-menu>
                <p class="error--text">{{server_errors.expired_date}}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Description"
                    v-model="promotion.description"
                    outlined
                    dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols>
                <v-select
                    required
                    v-model="promotion.status"
                    :items="status"
                    item-text="name"
                    item-value="id"
                    label="ສະຖານະ *"
                    outlined
                    dense
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="Back()">
            Back
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="Update()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import manage from "@/mixins/platform/manage_promotion";

export default {
  // mixins: [manage],
  data(){
    return {
      loading: false,
      expired_date: '',
      expired_menu: false,
      promotion: {},
      limit_min_price: 1,
      limit_use: 0,
      promotions: [],
      server_errors: {
        pro_code: '', description: '', name: ''
      },
      selectedProType: 'money',
      promotionType: [
        {
          id: 'water',
          name: 'ແຖມຕຸກນ້ຳ'
        },
        {
          id: 'money',
          name: 'ຈຳນວນເງິນ'
        },
        {
          id: 'percent',
          name: 'ສ່ວນຫຼຸດເປີເຊັນ'
        }
      ],
      status: [
        {
          id: 'open',
          name: 'Open'
        },
        {
          id: 'close',
          name: 'Close'
        },
      ],
    }
  },
  methods:{
    getData() {
      this.TableLoading = true;
      this.$admin.get('promotion/'+this.$route.params.id).then(res => {
        this.promotion = res.data.data;
        (this.promotion)
        this.TableLoading = false;
      }).catch(() => {
        this.TableLoading = false;
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    Back(){
      this.$router.go(-1);
    },
    Update() {
      const data = {
        pro_code: this.promotion.pro_code,
        name: this.promotion.name,
        description: this.promotion.description,
        limit_min_price: this.limit_min_price,
        buy_min_price: this.promotion.buy_min_price,
        limit_use: this.promotion.limit_use,
        amount_use: this.promotion.amount_use,
        pro_type: this.promotion.pro_type,
        volume: this.promotion.volume,
        expired_date: this.promotion.expired_date,
        status: this.promotion.status,
        specific: this.promotion.specific,
      };
      if (this.$refs.form.validate() == true) {
        this.$admin.post('/promotion/update/'+this.$route.params.id, data)
            .then(res => {
              if (res.status == 200) {
                // this.$store.commit("Toast_State", this.toast);
                setTimeout(() => {
                  this.loading = false;
                  this.promotion = {},
                  this.Back();
                  // this.$store.commit("Toast_State", this.toast);
                }, 300);
              }
            }).catch(error => {
          this.loading = false;
          if (error.response.status == 422) {
            const obj = error.response.data.errors;
            for (let [key, user] of Object.entries(obj)) {
              this.server_errors[key] = user[0];
            }
          }
          this.$store.commit("Toast_State", this.toast_error);
        });
      }
    },

  },
  watch:{
    'promotion.specific':function (value){
      if(value == false){
        this.specific = 0;
      } else if(value == true){
        this.specific = 1;
      }
    },
    'promotion.limit_min_price':function (value){
      if(value == false){
        this.buy_min_price = '';
        this.limit_min_price = 0;
      } else if(value == true){
        this.limit_min_price = 1;
      }
    },
    'promotion.limit_use':function (value){
      if(value == false){
        this.amount_use = '';
        this.limit_use = 0;
      } else if(value == true){
        this.limit_use = 1;
      }
    },
    'promotion.pro_code': function () {
      this.server_errors.pro_code = '';
    },
    'promotion.name': function () {
      this.server_errors.name = '';
    },
    'promotion.expired_date': function () {
      this.server_errors.expired_date = '';
    },
    'promotion.volume': function () {
      this.server_errors.volume = '';
    },
    'promotion.buy_min_price': function () {
      this.server_errors.buy_min_price = '';
    },
  },
  created() {
    this.getData();
  }

};
</script>

<style>
.primary-color {
  color: #00c1d2;
}

@import "../../../../public/scss/main.scss";
</style>